import request from '@/utils/http'

/**
 * 获取文档列表
 * @param payload
 */
export function get_doc_list(payload) {
    return request({ url: '/client/developer/lists', method: 'post', data: payload })
}

/**
 * 获取文档详情
 * @param payload
 */
export function get_doc_detail(payload) {
    return request({ url: '/client/developer/detail', method: 'post', data: payload })
}

/**
 * 发布-更新新文档
 * @param payload
 */
export function publish(payload) {
    return request({ url: '/client/developer/publish', method: 'post', data: payload })
}

/**
 * 删除
 * @param payload
 */
export function delete_doc(payload) {
    return request({ url: '/client/developer/delete', method: 'post', data: payload })
}