<template>
  <div class="doc-body">
    <div class="doc-category">
      <div class="publish-doc">
        <a-button type="primary" @click="publish" size="small">发布文档</a-button>
      </div>

      <div class="doc-tree">
        <a-tree style="width:180px" :auto-expand-parent="true" :default-expand-all="true" v-if="treeData.length>0" v-model:selectedKeys="selectedKeys" :tree-data="treeData" :field-names="fieldNames" @select="selectDoc">
          <template #switcherIcon="{ switcherCls }">
            <DownOutlined :class="switcherCls" />
          </template>
        </a-tree>
        <a-skeleton v-else />
      </div>

    </div>
    <div class="doc-list" v-if="treeData.length>0">
      <div class="handle-bar">
        <a-button type="text" @click="editDoc" size="small">编辑</a-button>
        <a-button type="text" @click="deleteDoc" size="small">删除</a-button>
      </div>

      <div class="doc-title">
        {{detail.title}}
      </div>
      <div class="doc-content">
        <mavon-editor class="mavon-show-card" boxShadowStyle="none" previewBackground="#fff" v-model="detail.content" :subfield="false" :external-link="externalLink" defaultOpen="preview" :toolbarsFlag="false" :editable="false" :scrollStyle="true" />
      </div>
    </div>
    <div class="doc-list" v-else>
      <a-empty />
    </div>
  </div>

  <a-modal v-model:visible="space_visible" width="1000px" @ok="handleSubmit" @cancel="handleCancel" title="发布文档">
    <a-form ref="formRef" :model="formState">

      <a-form-item label="文档标题" name="title" :rules="[{ required: true, message: '请输入文档标题!' }]">
        <a-input type="text" v-model:value="formState.title" placeholder="请输入文档标题" />
      </a-form-item>

      <a-form-item label="所属上级" name="parent_id" :rules="[{ required: true, message: '请选择所属上级!' }]">
        <a-select v-model:value="formState.parent_id">
          <a-select-option value="0">顶级</a-select-option>
          <a-select-option :value="item.doc_id.toString()" v-for="(item,index) in treeData" :key="index">{{item.title}}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="文档内容" name="content" :rules="[{ required: true, message: '请输入文档内容' }]">
        <mavon-editor v-model="formState.content" :external-link="externalLink" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { DownOutlined } from "@ant-design/icons-vue";
import { message, Modal, Form } from "ant-design-vue";
const useForm = Form.useForm;
import * as Api from "./api";
import "./style/document.less";

export default defineComponent({
  name: "Document",
  components: {
    DownOutlined,
  },
  props: {
    apptype: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    // 弹框是否显示
    const space_visible = ref(false);
    const formRef = ref();

    const formState = reactive({
      doc_id: 0,
      title: "",
      parent_id: "0",
      app_type: props.apptype,
      content: "",
    });

    const publish = () => {
      resetFields();
      space_visible.value = true;
    };

    const { resetFields } = useForm(formState);

    // 确定选择触发
    const handleSubmit = () => {
      formRef.value.validate().then(() => {
        console.log(formState);
        Api.publish(formState).then((res) => {
          if (res.status == 200) {
            onLoadData();
            message.success(res.message);
          } else {
            message.error(res.message);
          }
          resetFields();
          handleCancel();
        });
      });
    };

    // 取消选择
    const handleCancel = () => {
      space_visible.value = false;
    };

    const treeData = ref([]);

    const selectedKeys = ref([0]);

    const selectDoc = (event) => {
      let curKey = 0;
      if (event.length > 0) {
        curKey = event[0];
      } else {
        curKey = treeData.value[0]["doc_id"];
      }
      selectedKeys.value = [curKey];
      getDocDetail(curKey);
    };

    const onLoadData = () => {
      Api.get_doc_list({ app_type: props.apptype }).then((res) => {
        treeData.value = res.data;
        if (res.data.length > 0) {
          selectedKeys.value = [res.data[0]["doc_id"]];
          getDocDetail(res.data[0]["doc_id"]);
        }
      });
    };

    const detail = reactive({
      title: "",
      content: "",
    });

    const getDocDetail = (doc_id) => {
      Api.get_doc_detail({ doc_id: doc_id }).then((res) => {
        if (res.status == 200) {
          detail.title = res.data.title;
          detail.content = res.data.content;
        }
      });
    };

    const editDoc = () => {
      let doc_id = selectedKeys.value[0];
      Api.get_doc_detail({ doc_id: doc_id }).then((res) => {
        if (res.status == 200) {
          console.log(res);
          formState.doc_id = res.data.doc_id;
          formState.title = res.data.title;
          formState.parent_id = res.data.parent_id.toString();
          formState.app_type = res.data.app_type;
          formState.content = res.data.content;
          space_visible.value = true;
        }
      });
    };

    const deleteDoc = () => {
      Modal.confirm({
        title: "确定要删除吗?",
        content: "删除后将无法恢复、请谨慎操作！",
        onOk() {
          let doc_id = selectedKeys.value[0];
          Api.delete_doc({ doc_id: doc_id }).then((res) => {
            if (res.status == 200) {
              message.success(res.message);
              onLoadData();
            } else {
              message.error(res.message);
            }
          });
        },
      });
    };

    onMounted(() => {
      onLoadData();
    });

    return {
      space_visible,
      formRef,
      formState,
      publish,
      handleSubmit,
      handleCancel,
      treeData,
      selectedKeys,
      selectDoc,
      detail,
      editDoc,
      deleteDoc,
      fieldNames: {
        key: "doc_id",
        title: "title",
        label: "title",
        children: "children",
        value: "doc_id",
      },
      externalLink: {
        markdown_css: false,
        hljs_js: () => "/md/highlightjs/highlight.min.js",
        hljs_css: (css) => "/md/highlightjs/styles/" + css + ".min.css",
        hljs_lang: (lang) => "/md/highlightjs/languages/" + lang + ".min.js",
        katex_css: () => "/md/katex/katex.min.css",
        katex_js: () => "/md/katex/katex.min.js",
      },
    };
  },
});
</script>
<style scoped>
@import "/md/markdown/github-markdown.min.css";
</style>