<template>
  <div>
    <DeveloperDocument :apptype="'native'" />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { DeveloperDocument } from "@/components/Developer";

export default defineComponent({
  components: { DeveloperDocument },
});
</script>